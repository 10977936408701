<template>
  <div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

    <!-- ==== DIALOG DELETE ==== -->
    <v-dialog v-model="dialog.delete" persistent max-width="450">
      <v-card>
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5 text-center body-2">
          Apakah anda yakin ingin menghapus rekap data ini ?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pb-0 px-0">
          <v-alert
            type="error"
            text
            dense
            prominent
            v-show="response.length > 0"
            v-html="response"
          >
          </v-alert>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :loading="process.form"
            :disabled="process.form"
            class="white--text text-capitalize"
            @click="deletes"
          >
            Yakin
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.form"
            @click="dialog.delete = false; detail = {}"
          >
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog v-model="dialog.detail" persistent width="450" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs' ? true : false">
      <v-card :class="$vuetify.breakpoint.name === 'xs' ? '' : 'border-radius box-shadow'">
        <v-card-title
          class="body-2 white--text"
          style="background-color: #d31145;"
        >
          Datail Rekap Transaksi
        </v-card-title>
        <v-card-text class="pt-5" style="height: 500px;">
          <div>
            <v-list-item-title class="body-2 grey--text text--darken-2">
              <p class="caption grey--text text-lighten-4 mb-0">
                Nama Rekap
              </p>
              {{ detail.name }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-0">
                Range Rekap Data
              </p>
              {{ detail.start_month | monthYear }} - {{ detail.end_month | monthYear }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-0">
                Status
              </p>
              <div
                :class="
                  detail.status == 'Selesai'
                    ? 'caption font-weight-bold teal--text'
                    : 'caption font-weight-bold red--text text--darken-2'
                "
              >
                {{ detail.status }}
              </div>
            </v-list-item-title>
          </div>

          <v-list-item-title class="body-2 grey--text text--darken-2 mt-3">
            <p class="subtitle-2 grey--text text-lighten-4 mb-0">
              Daftar Outlet
            </p>
          </v-list-item-title>
          <v-divider></v-divider>
          <v-card 
            v-for="(item, key) in store_array" 
            :key="key"
            flat>
            <v-card-subtitle class="pa-2">
              {{ item.store_name }} - {{ item.merchant_name }}
            </v-card-subtitle>
            <v-divider></v-divider>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-5 py-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.detail = false; store_array = []"
          >
            <v-icon color="#d31145" small class="mr-1"
              >mdi-close-circle</v-icon
            >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG FORM -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ?  "Rekap Data Transaksi Baru" : "Ubah Rekap Data Transaksi" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan rekap data transaksi" : "Ubah rekap data transaksi" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <!-- {{ form }} -->
          <ValidationObserver ref="validation-new-recap-transaction">
            <v-form @:keyup.enter="save(dialog.form_type)">

              <ValidationProvider
                ref="form-name"
                name="Judul Rekap"
                rules="required|max:100"
                v-slot="{ errors }">
                <div class="subtitle-2 font-weight-bold">
                  Judul Rekap
                  <small style="color: #e74c3c"><i>*wajib</i></small>
                </div>
                <v-text-field
                  solo
                  flat
                  background-color="#EEEEEE"
                  counter
                  maxlength="50"
                  dense
                  color="#d31145"
                  placeholder="Tuliskan Judul Rekap"
                  v-model="form.name"
                  :error-messages="errors"
                  required
                >
                </v-text-field>
              </ValidationProvider>

              <ValidationProvider
                ref="form-start_month"
                name="Bulan Mulai Rekap"
                rules="required|max:50"
                v-slot="{ errors }"
              >
                <div class="subtitle-2 font-weight-bold">
                  Bulan Mulai Rekap
                  <small style="color: #e74c3c"><i>*wajib</i></small>
                </div>

                <v-menu
                  v-model="start_month"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="FormatStartMonth"
                      placeholder="Pilih Bulan Mulai Rekap"
                      solo
                      flat
                      background-color="#EEEEEE"
                      hide-details
                      dense
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    color="#d31145"
                    type="month"
                    v-model="form.start_month"
                    @input="start_month = false"
                  ></v-date-picker>
                </v-menu>
                
              </ValidationProvider>

              <div>
                <ValidationProvider
                  ref="form-end_month"
                  name="Bulan Selesai Rekap"
                  rules="required|max:50"
                  v-slot="{ errors }"
                >
                  <div class="subtitle-2 mt-5 font-weight-bold">
                    Bulan Selesai Rekap
                    <small style="color: #e74c3c"><i>*wajib</i></small>
                  </div>

                  <v-menu
                    v-model="end_month"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="FormatEndMonth"
                        placeholder="Pilih Bulan Selesai Rekap"
                        solo
                        flat
                        background-color="#EEEEEE"
                        hide-details
                        dense
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on">
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="#d31145"
                      type="month"
                      v-model="form.end_month"
                      @input="end_month = false"
                    ></v-date-picker>
                  </v-menu>
                  
                </ValidationProvider>
              </div>

              <ValidationProvider
                ref="form-store_array"
                name="Pilih Outlet"
                rules="required|max:50"
                v-slot="{ errors }">
                <div class="subtitle-2 mt-5 font-weight-bold">
                  Pilih Outlet
                  <small style="color: #e74c3c"><i>*wajib</i></small>
                </div>
                <v-autocomplete
                  solo
                  flat
                  dense
                  color="#d31145"
                  v-model="form.store_array"
                  return-object
                  multiple
                  chips
                  background-color="#EEEEEE"
                  :items="data_store"
                  item-text="store_name"
                  item-value="store_id"
                  :error-messages="errors"
                  required>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content style="border-bottom: 1px solid #eee">
                        <v-list-item-title>{{ data.item.store_name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.owner_name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </ValidationProvider>

            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Simpan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DATA STOCK ==== -->
    <v-row class="justify-space-between">
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 6">
        <div class="text--secondary mb-2" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
          Data Rekap Transaksi
        </div>
        <div class="d-flex">
          <v-btn
            depressed
            :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0"
            color="#d31145"
            :disabled="process.run"
            @click="actions(null, 'add')"
            class="white--text text-capitalize mb-3 mr-2 rounded-lg"
          >
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Tambah</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-plus</v-icon>
          </v-btn>
          <!-- <Export
            class="mb-3 mr-2"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="`franchise/stock/export_excel?store_id=${selected_store_id}`"
            :title="'Data Stok'"
            ref="base-export"
            v-on:onClickExport="responseExport"
          />
          <Export
            class="mb-3 mr-2"
            :disabled="$store.state.loading"
            :file_type="'pdf'"
            :btnText="'Export PDF'"
            :btnIcon="'mdi-file-pdf-box'"
            :btnColor="'#f40f02'"
            :url="`franchise/stock/export_excel?store_id=${selected_store_id}`"
            :title="'Data Stok'"
            ref="base-export-pdf"
            v-on:onClickExport="responseExport"
          /> -->
          <!-- <v-btn
            depressed
            :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#aaa9ad"
            class="white--text text-capitalize"
            @click="$refs['base-table'].isFilterVisible = true"
          >
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
          </v-btn> -->
        </div>
      </v-col>

      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <v-text-field
          outlined
          dense
          :hide-details="true"
          v-model="searching"
          placeholder="Cari Data . . ."
          clearable
          color="#d31145"
          @click:clear="
            searching = '';
            fetch();
            showPage = false;
          "
          v-on:keyup.enter="
            fetch();
            showPage = false;
          "
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-alert
      v-if="$vuetify.breakpoint.name !== 'xs'"
      dense
      text
      type="info">
      <strong>Mohon Perhatian !</strong> Permintaan tambah data rekap penjualan maksimal hanya bisa dilakukan 1 kali dalam 1 hari. Proses rekap akan dimulai otomatis oleh sistem pada jam 00.05. Mohon ditunggu.
    </v-alert>
    <v-divider style="display: none;" class="mt-4" v-if="$vuetify.breakpoint.name !== 'xs'"></v-divider>

    <!-- ==== LIST DATA MONITORING STOK ==== -->
    <div class="my-5" :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'min-height: calc(100vh - 485px)'">
      <v-skeleton-loader
        :loading="process.run"
        transition="scale-transition"
        :tile="false"
        type="table-tbody"
      >
        <!-- ====BASE TABLE==== -->
        <base-table
          :customHeight="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '' : 'calc(100vh - 460px)'"
          v-show="displayBaseTable"
          :isExternalFilterButton="true"
          :isFilterOnHeader="true"
          ref="base-table"
          :filterHeader="filterHeader_stock"
          itemKey="id"
          :single-select="singleSelect"
          :headers="headers"
          retrieveDataURL="franchise/report/recap_job/transaction"
          v-on:onRetrieveDataEnd="responseDataEnd"
        >
        <!-- ?filter[5][type]=string&filter[5][field]=product_is_have_variant&filter[5][comparison]===&filter[5][value]=Tidak -->
          <template v-slot:[`item.aksi_table`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-show="item.status === 'Selesai'"
                  min-width="30"
                  class="text-capitalize pa-0 mr-1"
                  @click="actions(item, 'view')"
                >
                  <v-icon color="orange">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Lihat Rekap Data Penjualan</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-show="item.status !== 'Selesai'"
                  min-width="30"
                  color="primary"
                  class="text-capitalize pa-0 mr-1"
                  @click="actions(item, 'edit')"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Rekap Data</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  min-width="30"
                  color="red"
                  class="text-capitalize pa-0 mr-1"
                  @click="actions(item, 'delete')"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Hapus Rekap Data</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div
              :class="
                item.status == 'Selesai'
                  ? 'caption font-weight-bold text-center teal--text'
                  : 'caption font-weight-bold text-center red--text text--darken-2'
              "
            >
              {{ item.status }}
            </div>
          </template>

          <template v-slot:[`item.total_store`]="{ item }">
            <span class="mr-3 body-2 font-weight-bold">{{ item.total_store }}</span>
            <v-btn 
              text small 
              outlined
              class="text-capitalize" 
              color="primary"
              @click="actions(item, 'outlet')">
              Lihat
            </v-btn>
          </template>

          <template v-slot:[`item.start_month`]="{ item }">
            {{ item.start_month | monthYear }} - {{ item.end_month | monthYear }}
          </template>

          <template v-slot:filterTitle>
            Filter Data Stok
          </template>
        </base-table>
      </v-skeleton-loader>
      <!-- ====END BASE TABLE ==== -->

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-model="contentEmpty" v-show="emptyData" />
    </div>
    <!-- <v-divider v-show="pagination.data.total_page > 0" class="mt-0"></v-divider> -->
  </div>
</template>
<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Export from "@/components/Export";
import moment from 'moment'
export default {
  data() {
    return {
      start_month: false,
      end_month: false,
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      arr_categoryProduct: [],
      singleSelect: true,
      headers: [
        {
          text: "Aksi",
          sortable: false,
          width: "10",
          value: "aksi_table",
          align: "right",
        },

        {
          text: "Judul Rekap",
          sortable: true,
          width: "150",
          value: "name",
          align: "start",
        },

        {
          text: "Total Outlet",
          sortable: true,
          width: "50",
          value: "total_store",
          align: "center",
        },

        {
          text: "Status",
          width: "100",
          sortable: true,
          value: "status",
          align: "center",
        },
        {
          text: "Range Rekap Data",
          width: "100",
          sortable: true,
          value: "start_month",
          align: "center",
        },
      ],
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      /**
       * END DATA COMPONENT BASETABLE
       */

      feedback: "",
      form: {
        name: "",
        start_month: "",
        end_month: "",
        store_array: []
      },

      store_array: [],
      color: "",
      response: "",
      searching: "",
      contentEmpty: "",
      detail: {},
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
      avatar: null,
      showPage: false,
      showPreviewImage: null,
      selected: {
        pagination: 0,
        menu: 0,
      },
      form: {
        user_id: "",
      },
      process: {
        form: false,
        run: false,
      },
      dialog: {
        delete: false,
        ImagePreview: false,
        detail: false,
        form: false,
        form_type: "",
      },
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      list: [],
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  watch: {
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;
      // this.fetch();
      this.$refs["base-table"].searchVal = newVal;
      this.$refs["base-table"].retrieveData();
    }, 500),
    // "selected.pagination": function(val) {
    //   this.fetch(val + 1);
    // },
    "selected.menu": function(val) {
      this.selected.menu = val === undefined ? 0 : this.selected.menu;

      // this.fetch();
    },
  },
  created() {
    // this.fetch();
    // this.getCategoryProduct();
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
    FormatStartMonth () {
      return this.form.start_month ? moment(this.form.start_month).format('MMMM YYYY') : ''
    },
    FormatEndMonth () {
      return this.form.end_month ? moment(this.form.end_month).format('MMMM YYYY') : ''
    },
    /**
     * FILTER HEADER STOCK
     */
    filterHeader_stock() {
      return [
        // {
        //   type: "opsi",
        //   column: "",
        //   valueDefault: "",
        //   label: "",
        // },

        {
          type: "text",
          column: "name",
          valueDefault: "",
          label: "Berdasarkan Nama Produk",
        },
        {
          type: "autocomplete",
          column: "category_name",
          valueDefault: "",
          items: this.arr_categoryProduct,
          label: "Berdasarkan Kategori",
        },
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "select",
          column: "is_active_bool",
          valueDefault: "",
          label: "Pilih Status",
          items: ["Aktif", "Tidak Aktif"],
        },
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "text",
          column: "stock_balance",
          valueDefault: "",
          label: "Berdasarkan Stok Sekarang",
        },
      ];
    },
    /**
     * END FILTER HEADER STOCK
     */
    data_store() {
      return JSON.parse(localStorage.getItem('user_store'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    row_classes(item) {
      // console.log(item)
        if (item.color != '#FFFFFF') {
          return "color_table";
        } 
    },

    actions(item, type) {
      switch (type) {
        case 'add':
          this.form = {
            id: "",
            name: "",
            start_month: "",
            end_month: "",
            store_array: []
          }
          this.dialog = {
            form: true,
            form_type: "add",
          }
          break;
        case 'edit':
          this.form = {
            id: item.id,
            name: item.name,
            start_month: item.start_month,
            end_month: item.end_month,
          }
          this.getStoreReportRecap(item.id, 'edit')
          break;
        case 'delete':
          this.detail = item
          this.dialog.delete = true
          break
        case 'outlet':
          this.getStoreReportRecap(item.id, 'outlet')
          this.detail = item
          this.dialog.detail = true
          break
        case 'view':
          this.detail = item
          // :to="`/report-recap-sales/${item.id}`"
          this.getDetailRecap(item.id)
          break
      }
    },

    async getStoreReportRecap(id, type) {
      const response = await get(`franchise/report/recap_job/transaction/store`, {
        params: {
          id, 
          pagination_bool: false,
        }
      })

      let res = response.data

      if (res.status == 200) {
        if (type == 'edit') {
          this.form.store_array = res.results.data
          setTimeout(() => {
            this.dialog = {
              form: true,
              form_type: "edit",
            }
          }, 500);
        }else {
          this.store_array = res.results.data
        }
      }
    },

    async getDetailRecap(id) {
      await get(`franchise/report/recap_job/transaction/detail?id=${id}`).then(async (response) => {
        let res = response.data;
        if (res.status == 200) {
          localStorage.setItem("setStoreRecapDetail", JSON.stringify(res.results.data))
          this.getStore(id)
        }
      })
    },
    async getStore(id) {
      await get(`franchise/report/recap_job/transaction/store`, {
        params: {
          id,
          pagination_bool: false
        }
      }).then((response) => {
        let res = response.data;
        if (res.status == 200) {
          localStorage.setItem("setStoreRecap", JSON.stringify(res.results.data))

          this.$router.push(`/report-recap-sales/${id}`);
        }
      })
    },
    /**
     * HANDLE EXPORT
     */
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export"].params = this.$refs["base-table"].params;

      // KALAU MAU PAKE SEARCH
      this.$refs["base-export"].params.search = this.$refs[
        "base-table"
      ].searchVal;

      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;
    },
    /**
     * END HANDLE EXPORT
     */

    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data belum tersedia atau data yang Anda cari tidak tersedia";
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    async toDetail(item) {
      this.detail = item;
      this.dialog.detail = true;
      this.getStoreReportRecap(item.id, 'detail')
    },

    async deletes() {
      this.process.form = true;
      this.responseSuccess = "";
      await post(`franchise/report/recap_job/transaction/delete`, {
        data: {
          id: this.detail.id,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.dialog.delete = false;
            this.process.form = false;
            this.form.id = ""
            
            this.snackbar.show = true;
            this.snackbar.color = "success";
            this.snackbar.text = res.message;

            this.$refs["base-table"].retrieveData();
          } else {
            this.process.form = false;

            this.snackbar.show = true;
            this.snackbar.color = "error";
            this.snackbar.text = res.message;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.// $store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },

    async save(type) {
      let _self = this;

      this.feedback = "";
      

      const isValid = await this.$refs["validation-new-recap-transaction"].validate();

      if (isValid) {
        this.process.run = true;

        let url = ""
        let data = {}
        if (type == "add") {
          url = `franchise/report/recap_job/transaction`
          data = this.form
        }else {
          url = `franchise/report/recap_job/transaction/update`
          data = this.form
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-recap-transaction"].reset();

              Object.assign(this.form, {
                name: "",
                email: "",
                password: "",
                password_confirm: "",
              });

              this.feedback = "";

              this.snackbar.show = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Berhasil menyimpan data";

              this.$refs["base-table"].retrieveData();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = "Permintaan gagal!. " + res.message;
              }
            }
          })
          .catch(function (error) {
            // console.log(error);
            // this.snackbar.show = true;
            // this.snackbar.color = "red";
            // this.snackbar.text = "Terjadi kendala pada server.";
          });
        this.process.run = false;
      }
    },

    // changePage(event) {
    //   if (event === undefined) {
    //     this.selected.pagination = 0;
    //   }
    // },
  },
};
</script>

<style>
.line:hover {
  border-left: 6px solid #d31145;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s;
}
.background-cols {
  background-color: #ffca8e61;
  border: 3px solid #ffc58461;
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}
.pagination {
  position: fixed;
  bottom: 65px;
}
.color_table {
  background-color: #FFCDD2 !important;
}
</style>
